interface InlineIconProps {
  uiIcon: string
  visible?: boolean
  class?: string
}

export function Icon({ uiIcon, class: className, visible }: InlineIconProps) {
  const icon = site_icons[uiIcon];

  if (visible === false || icon === undefined) {
    return (
      <span aria-hidden="true" class={className} />
    );
  }

  return (
    <span aria-hidden="true" class={className}
      dangerouslySetInnerHTML={{ __html: icon }} />
  );
}
