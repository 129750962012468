import { useAwaited } from 'ts-comp/jsx_hooks';
import TranslatedText from 'ts-comp/translated_text';

//request translations as soon as this file is referenced
window.translations = window.translations || getTranslations();

//#region Helper functions

//fetch without cache buster. We want to cache the translations if we can
async function directFetch(url: string) {
  return await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
}

async function getTranslations() {
  const lang = document.documentElement.lang.toLowerCase();
  const response = await directFetch(`/api/translate/${lang}`);
  return response.json() as Promise<TranslationDict>;
}

function applyFormatting(value: string, settings: Translations, customFormatting?: Translations) {
  for (const key of Object.keys(settings)) {
    value = value.replaceAll(key, settings[key]);
  }
  if (customFormatting !== undefined) {
    for (const key of Object.keys(customFormatting)) {
      value = value.replaceAll(key, customFormatting[key]);
    }
  }
  return new TranslatedText(value);
}

//#endregion

//#region exported functions and Components

/**
 * State hook for translations
 * @param strings Array of strings to translate
 * @returns Stateful array of translated strings
 * @example const [trn1, trn2] = useTranslated('string 1', 'string 2');
 */
export function useTranslated(...strings: string[]) {
  return useAwaited(
    () => translateMultiple(strings),
    strings.map(s => new TranslatedText(''))
  ).map(s => s.toString());
}

export async function translateMultiple(toTranslate: string[]) {
  return Promise.all(toTranslate.map(async t => await translateText(t)));
}

/** Render translated text inline
 * @param value Text to be translated
 * @param source Translation file to look in. Falls back to __global if not found
 * @param hasFormatting Apply HTML formatting if translation contains ## format markers
 * @example
 * await translateText('My text goes here', 'messages', false);
 */
export async function translateText(
  value: string | TranslatedText,
  source?: string,
  hasFormatting?: boolean,
  customFormatting?: Translations,
  debug?: boolean
) {
  if (!value) {
    if (debug) {
      console.log('Nothing to translate');
    }
    return new TranslatedText('');
  }

  if (value instanceof TranslatedText) {
    if (debug) {
      console.log('already translated', value);
    }
    return value;
  }
  const library = await window.translations;

  if (source !== undefined) {
    if (source in library) {
      const translations = library[source];
      if (value in translations) {
        if (debug) {
          console.log('found translation in  ', source, translations[value]);
        }
        if (hasFormatting === true) {
          if (debug) {
            console.log('formatting ', translations[value], customFormatting);
          }
          return applyFormatting(translations[value], library['__siteSettings'], customFormatting);
        }
        return new TranslatedText(translations[value]);
      }
    }
  }
  if ('__global' in library) {
    const translations = library['__global'];
    if (value in translations) {
      if (debug) {
        console.log('found translation in global', translations[value]);
      }
      if (hasFormatting === true) {
        if (debug) {
          console.log('formatting ', translations[value], customFormatting);
        }
        return applyFormatting(translations[value], library['__siteSettings'], customFormatting);
      }
      return new TranslatedText(translations[value]);
    }
  }

  if (debug) {
    console.log('no translation found');
  }
  if (hasFormatting === true) {
    if (debug) {
      console.log('formatting ', value, customFormatting);
    }
    return applyFormatting(value, library['__siteSettings'], customFormatting);
  }
  return new TranslatedText(value);
}
