import { useSignal } from '@preact/signals';
import { useEffect } from 'preact/hooks';
import TranslatedText from 'ts-comp/translated_text';

import { translateText } from '../components/translate';

interface TranslateProps {
  children: string;
  source?: string;
  hasFormatting?: boolean;
  customFormatting?: Translations;
  debug?: boolean; //set true to get console diagnostics of this translation
}

/** Render translated text inline
 * @param source Translation file to look in. Falls back to __global if not found
 * @param hasFormatting Apply HTML formatting if translation contains ## format markers
 * @example <Translate>My text goes here</Translate>
 */
export function Translate({
  children,
  source,
  hasFormatting,
  customFormatting,
  debug
}: TranslateProps) {
  const translated = useSignal(new TranslatedText(''));

  useEffect(() => {
    translateText(children, source, hasFormatting, customFormatting, debug).then(translation => {
      translated.value = translation;
    });
  }, [children]);

  if (hasFormatting) {
    if (debug) {
      console.log('formatted response', translated.value);
    }
    return <span dangerouslySetInnerHTML={{ __html: translated.value.text }} />;
  }

  if (debug) {
    console.log('unformatted response', translated.value);
  }
  return <>{translated.value.text}</>;
}

//#endregion
