import { useSignal } from '@preact/signals';
import { DateTime } from 'luxon';

import { HomepageDatePicker } from './homepage_date_picker';
import { Icon } from './icon';
import { Translate } from './translate';

interface Props {
  appointmentTypes: { id: number; name: string }[];
  searchType: 'timetable' | 'standard';
}

/** It is assumed that the parent page has had the local time zone currently set. */
export function SearchForm({ appointmentTypes, searchType }: Props) {
  // Using `now` intead of `utc` as it is assumed timezone has been configured.
  const date = useSignal(DateTime.now());
  const appType = useSignal(appointmentTypes[0]?.id);

  const noAppointments = appointmentTypes.length === 0;

  const doSearch = () => {
    if (!noAppointments) {
      window.location.href = `/search-results.aspx?appid=${appType}&date=${date.value.toISODate()}`;
    }
  };

  const searchTimetable = () => {
    if (!noAppointments) {
      window.location.href = `/timetable.aspx?appid=${appType}&date=${date.value.toISODate()}`;
    }
  };

  const selectedType = appointmentTypes.find(t => t.id === appType.value);

  return (
    <>
      <h1 class="is-hidden--text">
        <Translate>Search for an appointment</Translate>
      </h1>
      <div class="wrapper wrapper--narrow">
        <div class="search-widget">
          <div class="search-widget__fields">
            <div class="search-widget__field">
              <h3 class="search-widget__heading">
                <Translate>I'm Available From</Translate>
              </h3>
              <HomepageDatePicker date={date} />
            </div>
            <div class="search-widget__field">
              <h3 class="search-widget__heading">
                <Translate>I Want To Book A</Translate>
              </h3>
              <div class="search-widget__form-field">
                <div class="decor-field">
                  <span class="decor-field__value">{selectedType?.name}</span>
                  <select
                    class="hidden-field"
                    value={appType.value}
                    onChange={evt =>
                      (appType.value = Number((evt.target as HTMLSelectElement).value))
                    }>
                    {appointmentTypes.map(t => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </select>
                  <span class="decor-field__decor" role="presentation">
                    <Icon uiIcon="arrow_down" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="search-widget__message">
            {noAppointments && (
              <p>
                <Translate>No appointment types available to book</Translate>
              </p>
            )}
          </div>
          <div class="search-widget__actions">
            {searchType === 'standard' ? (
              <button
                type="button"
                class="button button--secondary button--rev button--large button--inline-pairs"
                onClick={() => doSearch()}>
                <span class="button__content">
                  <Translate hasFormatting>
                    #H#Click here to #/H# find the next available appointment
                  </Translate>
                </span>
                <span class="button__icon" role="presentation">
                  <Icon uiIcon="arrow_right" />
                </span>
              </button>
            ) : (
              <>
                <button
                  type="button"
                  class="button button--secondary button--rev button--large button--inline-pairs"
                  onClick={() => searchTimetable()}>
                  <span class="button__content">
                    <Translate hasFormatting>#H#Click here to #/H#see our availability</Translate>
                  </span>
                  <span class="button__icon" role="presentation">
                    <Icon uiIcon="arrow_right" />
                  </span>
                </button>
                <a class="search-widget__actions__secondary-action" onClick={doSearch}>
                  <Translate hasFormatting>
                    or #H#Click here to #/H#find our earliest available appointments
                  </Translate>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
