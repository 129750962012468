import { Settings, Zone } from 'luxon';

export function setTimeZone(timeZone?: string) {
  if (timeZone && typeof timeZone === 'string' && ((typeof Settings.defaultZone === 'string'
    && Settings.defaultZone !== timeZone)
  || (typeof Settings.defaultZone !== 'string' && Settings.defaultZone.name !== timeZone))) {
    Settings.defaultZone = timeZone;
  
    if (typeof Settings.defaultZone !== 'string' && !(Settings.defaultZone as Zone).isValid) {
      Settings.defaultZone = 'system';
    }
  }
}