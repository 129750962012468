import { useSignal, type Signal, useComputed } from '@preact/signals';
import { Icon } from '../icon';
import { Translate } from '../translate';
import { useCallback } from 'preact/hooks';
import { DateTime } from 'luxon';
import { getStructure } from './shared/structure';

interface Props {
  date: Signal<DateTime>;
  offset?: number;
  isOpen?: Signal<boolean>;
}

export function DatePickerBody(props: Props) {
  const offset = useSignal(props.offset || 0);

  const structure = useComputed(() => getStructure(offset.value, props.date.value));

  const onHide = useCallback(() => {
    if (props.isOpen === undefined) {
      return;
    }

    props.isOpen.value = false;
  }, []);

  const onDay = useCallback((evt: Event, day: DateTime) => {
    evt.preventDefault();

    if (day.equals(props.date.value)) {
      return;
    }

    props.date.value = day;
    offset.value = 0;

    onHide();
  }, []);

  const onMonth = useCallback((evt: Event, direction: 'prev' | 'next') => {
    evt.preventDefault();

    if (direction === 'prev') {
      offset.value -= 1;
    } else {
      offset.value += 1;
    }
  }, []);

  const today = DateTime.now();

  return (
    <div class={`date-picker${props.isOpen?.value === true ? ' is-active' : ''}`}>
      <div class="date-picker__header">
        <div class="date-picker__heading">
          <span>{structure.value.date?.toFormat('MMMM yyyy')}</span>
        </div>
        <a
          href="#"
          class="date-picker__navigation date-picker__navigation--prev"
          role="button"
          data-direction="prev"
          onClick={evt => onMonth(evt, 'prev')}>
          <Icon uiIcon="arrow_left" />{' '}
          <span class="is-hidden--text">
            <Translate>Click here to navigate to the next month</Translate>
          </span>
        </a>
        <a
          href="#"
          class="date-picker__navigation date-picker__navigation--next"
          role="button"
          data-direction="next"
          onClick={evt => onMonth(evt, 'next')}>
          <Icon uiIcon="arrow_right" />
          <span class="is-hidden--text">
            <Translate>Click here to navigate to the previous month</Translate>
          </span>
        </a>
      </div>
      <div class="date-picker__inner">
        <table class="date-picker__table">
          <thead>
            <tr>
              {structure.value.dayAbbreviations.map(day => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {structure.value.rows.map(
              row =>
                row.days.length && (
                  <tr key={row}>
                    {row.days.map(day =>
                      day !== undefined ? (
                        <td
                          key={day}
                          class={[
                            day.hasSame(today, 'day') ? 'is-today' : '',
                            day.equals(props.date.value) ? 'is-active' : ''
                          ]
                            .join(' ')
                            .trim()}>
                          {day < today ? (
                            <span class="is-disabled">{day.day}</span>
                          ) : (
                            <a href="#" role="button" onClick={evt => onDay(evt, day)}>
                              {day.day}
                            </a>
                          )}
                        </td>
                      ) : (
                        <td key={day} />
                      )
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <div class="date-picker__mask" onClick={onHide} />
    </div>
  );
}
