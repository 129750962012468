import { DateTime } from 'luxon';

interface Row {
  days: (DateTime | undefined)[];
}

interface Structure {
  dayAbbreviations: ('m' | 't' | 'w' | 't' | 'f' | 's')[];
  rows: Row[];
  date?: DateTime;
}

export function getStructure(offset: number | undefined, date: DateTime) {
  // Set default offset
  if (offset == undefined) {
    offset = 0;
  }

  let baseDate = date;

  // Create base date object
  const data: Structure = {
    dayAbbreviations: ['m', 't', 'w', 't', 'f', 's', 's'],
    rows: []
  };

  // Set the base date's month based on offset
  //baseDate.setMonth(baseDate.getMonth() + offset);
  if (offset !== 0) {
    baseDate = baseDate.set({ month: baseDate.month + offset });
  }

  // Return base date back in date
  data.date = baseDate;

  // Determine the first day of the month
  const firstDayOfMonth = baseDate.startOf('month');

  // Determine the count of days in this month by invoking the 'getMonthDayCount' method
  const dayCount = baseDate.daysInMonth!;

  // Determine start position
  const startPosition = firstDayOfMonth.weekday - 1;

  // Set a base row element
  let row: Row = {
    days: []
  };

  // Fill up the first row with blank cells until we reach the start position
  for (let i = 0; i < startPosition; i++) {
    row.days.push(undefined);
  }

  // Loop over each day
  for (let i = 0; i < dayCount; i++) {
    // If this is the last row
    if (i == dayCount - 1) {
      // Determine the count of blank days left
      let diff = data.dayAbbreviations.length - row.days.length;

      // There is only one day here so let's fill up a row with blanks and this day
      if (diff == 0) {
        // Push the row before in
        data.rows.push(row);

        // Set up a new row with this day
        row = {
          days: [baseDate.set({ day: i + 1 })]
        };

        // Fill up the blanks at one less than the days in a week (that's six but y'know, variables and that)
        for (let j = 0; j < data.dayAbbreviations.length - 1; j++) {
          row.days.push(undefined);
        }

        // Push this rown up
        data.rows.push(row);
      } else {
        // Push the last day
        row.days.push(baseDate.set({ day: dayCount }));

        // Remove one from the diff
        diff--;

        // If there still is a diff the fill up the row with blanks
        if (diff > 0) {
          for (let j = 0; j < diff; j++) {
            row.days.push(undefined);
          }
        }

        // Push the last row
        data.rows.push(row);
      }
    }

    // Standard loop action
    else {
      // If the last day in the row
      if (row.days.length == data.dayAbbreviations.length) {
        // Push up
        data.rows.push(row);

        // Add this day as the first day of the new row
        row = {
          days: [baseDate.set({ day: i + 1 })]
        };
      }

      // Just push in a day by default
      else {
        row.days.push(baseDate.set({ day: i + 1 }));
      }
    }
  }

  // Return the beauty
  return data;
}
