import { render } from 'preact';

import { setTimeZone } from '../components/time_zone';
import { SearchForm } from '../web/search_form';

declare const __page: {
  appointmentTypes: { id: number; name: string }[];
  searchType: 'timetable' | 'standard';
  timeZone?: string;
};

(() => {
  setTimeZone(__page.timeZone);

  const container = document.querySelector('.search-widget-container');
  if (container) {
    render(
      <SearchForm appointmentTypes={__page.appointmentTypes} searchType={__page.searchType} />,
      container
    );
  }
})();
