import { useSignal, type Signal } from '@preact/signals';
import { useCallback } from 'preact/hooks';

import { DatePickerBody } from './date_picker_body';
import { Icon } from './icon';
import { type DateTime } from 'luxon';

interface Props {
  date: Signal<DateTime>;
}

export function HomepageDatePicker({ date }: Props) {
  const isOpen = useSignal(false);

  const onToggle = useCallback(() => {
    isOpen.value = !isOpen.value;
  }, []);

  return (
    <div class="search-widget__form-field">
      <div class="decor-field">
        <span class="decor-field__value">{date.value.toFormat('dd/MM/yyyy')}</span>
        <input
          type="text"
          class="hidden-field"
          value={date.value.toISO() || ''}
          onClick={onToggle}
        />
        <span class="decor-field__decor" role="presentation">
          <Icon uiIcon="calendar" />
        </span>
      </div>
      <DatePickerBody isOpen={isOpen} date={date} />
    </div>
  );
}
